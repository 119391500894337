import React from 'react';
import { IntroLogo } from './IntroStyles';
import { ModalLayout, ModalWrapper, ModalButton } from './../../styles/sharedStyles';
import Logo from './CF_WildBritain Sting_white_no drop shadow.png';

const Intro = ({ setShowIntro }) => (
  <ModalLayout data-testid="Intro">
    <ModalWrapper>
      <IntroLogo>
        <img src={Logo} alt="Wild Britain" />
      </IntroLogo>
      <p>Countryfile’s Wild Britain aims to get the nation doing acts of kindness for nature.</p>
      <p>Share all those acts of kindness on our map and see what others across the country have achieved.</p>
      <p>Together we can all help our wildlife for Wild Britain.</p>
      <ModalButton onClick={() => setShowIntro(false)}>Get started</ModalButton>
    </ModalWrapper>
  </ModalLayout>
);

export default Intro;
