import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Home from '../../pages/Home/Home.lazy';
import Results from '../../pages/Results/Results.lazy';
import { useDispatch } from 'react-redux';
import { fetchWilds } from '../../store/state/actions';
import { enableTabbing } from '../../util';
import { MainStyle } from './MainStyles';
import Intro from '../../components/Intro/Intro';
import { useCookies } from 'react-cookie';
import Cookies from '../../components/Cookies/Cookies';
import { addYears } from 'date-fns';
const Main = () => {
    const [showIntro, setShowIntro] = useState(true);
    const [showCookies, setShowCookies] = useState(false);
    const [cookies, setCookie] = useCookies(['planted-visited']);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchWilds());
        enableTabbing();
    }, [dispatch]);

    useEffect(() => {
        if (showIntro && cookies['planted-visited']) {
            setShowIntro(false);
        }
    }, [cookies, showIntro]);

    useEffect(() => {
        if (!showIntro && !cookies['planted-visited']) {
            setCookie('planted-visited', true, { expires: addYears(new Date(), 1) });
        }
    }, [cookies, setCookie, showIntro]);

    return (
        <MainStyle className='Main'>
            {showIntro && <Intro setShowIntro={setShowIntro} />}
            <Router>
                <Header />
                <Routes>
                    <Route path='/' exact element={<Home showIntro={showIntro} />} />
                    <Route path='/results' exact element={<Results />} />
                </Routes>
                <Footer setShowCookies={setShowCookies} />
            </Router>
            {showCookies && <Cookies setShowCookies={setShowCookies} />}
        </MainStyle>
    );
};

export default Main;
