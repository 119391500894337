import { API } from 'aws-amplify';
import { SET_TYPE, SET_GEOJSON, SET_BG, SET_SEASON, ADD_PLANTING, UPDATE_PLANTING } from './constants';

export function setType(payload) {
    return {
        type: SET_TYPE,
        payload,
    };
}

export function setGeoJson(payload) {
    return {
        type: SET_GEOJSON,
        payload,
    };
}

export function setBg(payload) {
    return {
        type: SET_BG,
        payload,
    };
}

export function setSeason(payload) {
    return {
        type: SET_SEASON,
        payload,
    };
}

export function addWilding(payload) {
    return {
        type: ADD_PLANTING,
        payload,
    };
}

export function updateWilding(payload) {
    return {
        type: UPDATE_PLANTING,
        payload,
    };
}

const updateFeatures = (geojson, data) => {
    geojson.features.map((feature) => {
        const { wildlifeHomes, volunteeringForWildlife, feedingStations, waterForWildlife, total } = { ...data.find(d => d.areaCode === feature.properties.name) };
        const totals = {
            wildlifeHomes: wildlifeHomes || 0,
            volunteeringForWildlife: volunteeringForWildlife || 0,
            feedingStations: feedingStations || 0,
            waterForWildlife: waterForWildlife || 0,
            total: total || 0
        };

        feature.properties = {
            ...feature.properties,
            ...totals,
        };
        return feature;
    });
    return geojson;
};

export function fetchWilds(url) {
    return async (dispatch) => {
        try {
/*             const listAggregatedWilds =
            `query listAggregatedWilds {
                listAggregatedWilds(limit: 242) {
                    items {
                        areaCode
                        volunteeringForWildlife
                        fruitVegTotal
                        wildlifeHomes
                        feedingStations
                        waterForWildlife
                        total
                    }
                }
            }`; */
            const listAggregatedWildlives =
            `query ListAggregatedWildlives {
                listAggregatedWildlives(limit: 242) {
                    items {
                        areaCode
                        wildlifeHomes
                        feedingStations
                        waterForWildlife
                        volunteeringForWildlife
                        total
                    }
                }
            }`;

            const aggregatedData = await API.graphql({ query: listAggregatedWildlives });
            const postcodes = await fetch('https://d159os3caowhrk.cloudfront.net/postcode.json').then(response => response.json());
            console.log(aggregatedData);
            const geojson = updateFeatures(postcodes, aggregatedData.data.listAggregatedWildlives
                .items);
            dispatch(setGeoJson(geojson));
            const bg = await fetch('https://d159os3caowhrk.cloudfront.net/UK.json').then(response => response.json());
            dispatch(setBg(bg));
        } catch (error) {
            console.log(error);
        }
    };
}