import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Logo } from './logo.svg';
import { selectTotal, selectType } from '../../store/state/selectors';
import { HeaderBar, ItemsWilded, SkipToContent, HeaderBackground } from './HeaderStyles';
import AnimatedNumber from "animated-number-react";
import { plantTypes } from './../../util';

const Header = () => {
    const total = useSelector(selectTotal);
    const type = useSelector(selectType);
    const plantType = plantTypes.find(t => t.type === type);
    const formatValue = (value) => parseInt(value).toLocaleString();
    return (
        <HeaderBar className="Header" data-testid="Header">
            <SkipToContent href="#main">Skip to content</SkipToContent>
            <HeaderBackground>
                <div className="Header__Wrapper">
                    <Logo className="Header__Logo" />
                    <ItemsWilded>
                        <p>{plantType.header_title}</p>
                        <h2><AnimatedNumber value={total} formatValue={formatValue}></AnimatedNumber></h2>
                    </ItemsWilded>
                </div>
            </HeaderBackground>
        </HeaderBar>
    );
};

export default React.memo(Header);
