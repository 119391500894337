import { SET_BG, SET_GEOJSON, SET_TYPE, SET_SEASON, ADD_PLANTING, UPDATE_PLANTING } from "./constants";
import { season, getPostcodeName } from './../../util';

export const initialState = {
    season: season(),
    type: 'total',
    total: 0,
    geojson: {
        features: []
    },
    bg: null
};

const getColourRange = (season) => {
    const seasonColours = {
        spring: ['#F8F2C6', '#EEEFB6', '#DBE28C', '#C1D275', '#AFC26A', '#99BA4F', '#80AD3D', '#889D30'],
        summer: ['#F8F2C6', '#EEEFB6', '#DBE28C', '#C1D275', '#AFC26A', '#99BA4F', '#80AD3D', '#889D30'],
        autumn: ['#F8F2C6', '#EEEFB6', '#DBE28C', '#C1D275', '#AFC26A', '#99BA4F', '#80AD3D', '#889D30'],
        winter: ['#F8F2C6', '#EEEFB6', '#DBE28C', '#C1D275', '#AFC26A', '#99BA4F', '#80AD3D', '#889D30'],
    };
    return seasonColours[season];
};

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
        : null;
}

const getPercentage = (value, min, max) => {
    if (!Number(value)) {
        return 0;
    }

    const totalDiff = max - min;
    const valueDiff = value - min;
    let percentage = valueDiff / totalDiff * 100;
    return percentage;
};


const getMinMax = (features, type) => {
    const sorted = features.sort((a, b) => a.properties[type] - b.properties[type]);
    return {
        min: sorted.length ? sorted[0].properties[type] : 0,
        max: sorted.length ? sorted[sorted.length - 1].properties[type] : 0
    };
};

const setProperties = (geojson, type, season) => {
    const colours = getColourRange(season);
    const { min, max } = getMinMax([...geojson.features], type);
    geojson.features.map(feature => {
        const percentage = Math.floor(getPercentage(feature.properties[type], min, max));
        const index = Math.floor((colours.length - 1) * (percentage / 100));
        const colour = hexToRgb(colours[index]);
        const title = getPostcodeName(feature.properties.name);
        feature.properties = {
            ...feature.properties,
            title,
            height: 30000 + ((200000 / 100) * percentage),
            color: colour,
        };
        return feature;
    });
    return geojson;
};

const getTotal = (features, type) => {
    return features.length ? features.map(item => item.properties[type]).reduce((prev, next) => prev + next) : 0;
};

const stateReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_PLANTING:
            let features = [...state.geojson.features];
            features = features.map(f => {
                if (f.properties.name === action.payload.areaCode) {
                    f.properties.wildlifeHomes = f.properties.wildlifeHomes + action.payload.wildlifeHomes;
                    f.properties.feedingStations = f.properties.feedingStations + action.payload.feedingStations;
                    f.properties.waterForWildlife = f.properties.waterForWildlife + action.payload.waterForWildlife;
                    f.properties.volunteeringForWildlife = f.properties.volunteeringForWildlife + action.payload.volunteeringForWildlife;
                    f.properties.total = f.properties.total + action.payload.feedingStations + action.payload.waterForWildlife + action.payload.volunteeringForWildlife + action.payload.fruitVegTotal;
                }
                return f;
            });
            const getGeoJson = { ...state.geojson };
            getGeoJson.features = features;
            const addGeoJson = setProperties(getGeoJson, state.type, state.season);
            return {
                ...state,
                geojson: addGeoJson,
                total: getTotal(addGeoJson.features, state.type)
            };
        case UPDATE_PLANTING:
            let updateFeatures = [...state.geojson.features];
            updateFeatures = updateFeatures.map(f => {
                if (f.properties.name !== action.payload.areaCode) {
                    return f;
                }

                return {
                    ...f,
                    properties: {
                        ...f.properties,
                        feedingStations: action.payload.feedingStations,
                        waterForWildlife: action.payload.waterForWildlife,
                        volunteeringForWildlife: action.payload.volunteeringForWildlife,
                        total: action.payload.total,
                    }
                };
            });
            const getGeoJsonUpdate = { ...state.geojson };
            getGeoJsonUpdate.features = updateFeatures;
            const addGeoJsonUpdate = setProperties(getGeoJsonUpdate, state.type, state.season);
            return {
                ...state,
                geojson: addGeoJsonUpdate,
                total: getTotal(addGeoJsonUpdate.features, state.type)
            };
        case SET_TYPE:
            const updateGeoJson = setProperties(state.geojson, action.payload, state.season);
            return {
                ...state,
                type: action.payload,
                geojson: updateGeoJson,
                total: getTotal(updateGeoJson.features, action.payload)
            };
        case SET_GEOJSON:
            const newGeoJson = setProperties(action.payload, 'total', state.season);
            return {
                ...state,
                geojson: newGeoJson,
                total: getTotal(newGeoJson.features, state.type)
            };
        case SET_BG:
            return {
                ...state,
                bg: action.payload,
            };
        case SET_SEASON:
            return {
                ...state,
                season: action.payload,
            };
        default:
            return state;
    }
};

export default stateReducer;
