import { ReactComponent as Homes } from "./styles/icons/homes.svg";
import { ReactComponent as Feeding } from "./styles/icons/feeding.svg";
import { ReactComponent as Water } from "./styles/icons/water.svg";
import { ReactComponent as Volunteering } from "./styles/icons/volunteering.svg";

import postcodeData from "./data/postcodeArea";

export const SEASONS = ["spring", "summer", "autumn", "winter"];

export const season = () => {
  const month = new Date().getMonth() + 1;
  if (month >= 3 && month <= 5) {
    return SEASONS[0];
  }

  if (month >= 6 && month <= 8) {
    return SEASONS[1];
  }

  if (month >= 9 && month <= 11) {
    return SEASONS[2];
  }

  return SEASONS[3];
};

export const plantTypes = [
  {
    type: "total",
    title: "All",
    header_title: "All Activities",
    description: "",
  },
  {
    type: "wildlifeHomes",
    title: "Wildlife Homes",
    header_title: "Wildlife Homes",
    description: "",
  },
  {
    type: "feedingStations",
    title: "Feeding Stations",
    header_title: "Feeding Stations",
    description: "",
  },
  {
    type: "waterForWildlife",
    title: "Water for Wildlife",
    header_title: "Water for Wildlife",
    description: "",
  },
  {
    type: "volunteeringForWildlife",
    title: "Volunteering for Wildlife",
    header_title: "Volunteering for Wildlife",
    description: "",
  },
];

export const getIcon = (type) => {
  switch (type) {
    case "total":
      return <Homes />;
    case "waterForWildlife":
      return <Water />;
    case "wildlifeHomes":
      return <Homes />;
    case "volunteeringForWildlife":
      return <Volunteering />;
    case "feedingStations":
      return <Feeding />;
    default:
      break;
  }
};

export const enableTabbing = () => {
  const handleFirstTab = (e) => {
    if (e.keyCode === 9) {
      document.body.classList.add("tabbing");
      window.removeEventListener("keydown", handleFirstTab);
      // eslint-disable-next-line
      window.addEventListener("mousedown", handleMouseDownOnce);
    }
  };
  const handleMouseDownOnce = () => {
    document.body.classList.remove("tabbing");
    window.removeEventListener("mousedown", handleMouseDownOnce);
    window.addEventListener("keydown", handleFirstTab);
  };
  window.addEventListener("keydown", handleFirstTab);
};

export const getPostcodeName = (code) => {
  const getPostcode = postcodeData.find((p) => p.Postcode === code);
  return getPostcode ? getPostcode.Name : "";
};
