import Main from './layout/Main/Main';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/helpers';
import { season } from './util';
import { API, graphqlOperation } from 'aws-amplify';
import { publishAggregatedWilding } from './graphql/subscriptions';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectSeason } from './store/state/selectors';
import { updateWilding } from './store/state/actions';

function App() {
    const dispatch = useDispatch();
    const [currentTheme, setTheme] = useState(theme[season()]);
    const selectedSeason = useSelector(selectSeason);

    useEffect(() => {
        setTheme(theme[selectedSeason]);
    }, [selectedSeason]);

    useEffect(() => {
        const subscription = API.graphql(
            graphqlOperation(publishAggregatedWilding)
        ).subscribe({
            next: event => {
                if (event.value.data && event.value.data.publishAggregatedWilding) {
                    console.log('subscription: ', event.value.data.publishAggregatedWilding);
                    dispatch(updateWilding(event.value.data.publishAggregatedWilding));
                }
            },
            error: error => {
                console.error(error);
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [dispatch]);

    return (
        <ThemeProvider theme={currentTheme}>
            <Main />
        </ThemeProvider>
    );
}

export default App;
