import styled from 'styled-components';
import { device } from '../../styles/helpers';
import { isWebpSupported } from 'react-image-webp/dist/utils';

export const HeaderBar = styled.header`
    background-color: transparent;
    height: 94px;
    position: relative;
    z-index: 4;
    filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.5));

    @media ${device.tablet} { 
        height: 150px;
    }

    .Header__Wrapper {
        width: 100%;
        height: 49px;
        display: flex;
        justify-content: space-between;
        text-align: right;
        margin-top: -9px;

        @media ${device.tablet} { 
            height: 90px;
            margin-top: -15px;
        }
    }

    .Header__Logo {
        height: 49px;
        width: auto;

        @media ${device.tablet} { 
            height: 90px;
        }
    }
`;

export const HeaderBackground = styled.div`
    width: 100%;
    height: 100%;
    background-image: ${props => isWebpSupported() ? props.theme.backgroundImageWebp : props.theme.backgroundImage};
    background-size: cover;
    background-position: ${props => props.theme.backgroundPosition};
    padding: 10px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 50% 100%, 0% 90%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media ${device.tablet} { 
        padding: 10px 15px;
    }
`;

export const ItemsWilded = styled.div`
    color: #fff;
    align-self: flex-end;
    h2, p {
        margin: 0;
    }

    h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: -3px;

        @media ${device.tablet} { 
            font-size: 44px;
            margin-bottom: -6px;
        }
    }


    p { 
        font-size: 14px;
        font-weight: 500;

        @media ${device.tablet} { 
            font-size: 22px;
        }
    }
`;


export const SkipToContent = styled.a`
    height: 30px;
    padding: 5px 35px 6px;
    top: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -100%);
    text-decoration: none;
    color: white;
    background-color: ${props => props.theme.mid};
    z-index: 999;
    display: block;
    white-space: nowrap;

    &:focus {  
            transform: translate(-50%, 0%);
    }
`;
