/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const publishAggregatedWilding = /* GraphQL */ `
  subscription PublishAggregatedWilding {
    publishAggregatedWilding {
      areaCode
      wildlifeHomes
      feedingStations
      waterForWildlife
      volunteeringForWildlife
      total
    }
  }
`;
export const onCreateWildLife = /* GraphQL */ `
  subscription OnCreateWildLife(
    $filter: ModelSubscriptionWildLifeFilterInput
    $owner: String
  ) {
    onCreateWildLife(filter: $filter, owner: $owner) {
      id
      areaCode
      areaDescriptiveName
      wildlifeHomes
      feedingStations
      waterForWildlife
      volunteeringForWildlife
      type
      adminUser
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateWildLife = /* GraphQL */ `
  subscription OnUpdateWildLife(
    $filter: ModelSubscriptionWildLifeFilterInput
    $owner: String
  ) {
    onUpdateWildLife(filter: $filter, owner: $owner) {
      id
      areaCode
      areaDescriptiveName
      wildlifeHomes
      feedingStations
      waterForWildlife
      volunteeringForWildlife
      type
      adminUser
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteWildLife = /* GraphQL */ `
  subscription OnDeleteWildLife(
    $filter: ModelSubscriptionWildLifeFilterInput
    $owner: String
  ) {
    onDeleteWildLife(filter: $filter, owner: $owner) {
      id
      areaCode
      areaDescriptiveName
      wildlifeHomes
      feedingStations
      waterForWildlife
      volunteeringForWildlife
      type
      adminUser
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateAggregatedWildlife = /* GraphQL */ `
  subscription OnCreateAggregatedWildlife(
    $filter: ModelSubscriptionAggregatedWildlifeFilterInput
  ) {
    onCreateAggregatedWildlife(filter: $filter) {
      areaCode
      wildlifeHomes
      feedingStations
      waterForWildlife
      volunteeringForWildlife
      total
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAggregatedWildlife = /* GraphQL */ `
  subscription OnUpdateAggregatedWildlife(
    $filter: ModelSubscriptionAggregatedWildlifeFilterInput
  ) {
    onUpdateAggregatedWildlife(filter: $filter) {
      areaCode
      wildlifeHomes
      feedingStations
      waterForWildlife
      volunteeringForWildlife
      total
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAggregatedWildlife = /* GraphQL */ `
  subscription OnDeleteAggregatedWildlife(
    $filter: ModelSubscriptionAggregatedWildlifeFilterInput
  ) {
    onDeleteAggregatedWildlife(filter: $filter) {
      areaCode
      wildlifeHomes
      feedingStations
      waterForWildlife
      volunteeringForWildlife
      total
      createdAt
      updatedAt
    }
  }
`;
