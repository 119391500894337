/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "9c60ac2c5c5c4193a6aeb38770ce731f",
    "aws_mobile_analytics_app_region": "eu-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "9c60ac2c5c5c4193a6aeb38770ce731f",
            "region": "eu-west-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://2k2643yszndj7jgj5y2olsxow4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-hkpikco3pbc5rckzaknv6pxtge",
    "aws_cloud_logic_custom": [
        {
            "name": "verify",
            "endpoint": "https://26v5hdkfog.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:bc38b07f-3489-42bc-adc4-ffeaaf881159",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_lmqJSzAtO",
    "aws_user_pools_web_client_id": "3nvdojffkpajt43dhca5g3p6op",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
