import React from 'react';
import { ModalLayout, ModalButton } from './../../styles/sharedStyles';
import { CookiesWrapper } from './CookiesStyles';

const Cookies = ({ setShowCookies }) => (
  <ModalLayout data-testid="Intro">
    <CookiesWrapper>
      <h2>Cookies</h2>
      <p>Cookies are small text files which are transferred to your computer or mobile when you visit a website or app. More information about cookies, and how the BBC use them, can be found at <a href="https://www.bbc.co.uk/usingthebbc/cookies" target="_blank" rel="noopener noreferrer" >www.bbc.co.uk/usingthebbc/cookies</a></p>
      <p>The Countryfile Wild Britain map only uses strictly necessary cookies, which are required to provide this service.</p>
      <ModalButton onClick={() => setShowCookies(false)}>Close</ModalButton>
    </CookiesWrapper>
  </ModalLayout>
);

export default Cookies;
