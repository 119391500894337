import React from 'react';
import { Bar, StyledLink, StyledRouterLink, StyledButton } from './FooterStyles';


const Footer = ({ setShowCookies }) => (
  <Bar className="Footer" data-testid="Footer">
    <div className="Footer_links">
      <StyledLink href="https://bbc.co.uk" target="_blank" rel="noopener">© BBC</StyledLink>
      <StyledButton onClick={() => setShowCookies(true)}>Cookies</StyledButton>
      <StyledLink href="https://www.bbc.co.uk/programmes/articles/C5yw7XDsZlTKbSxd7kMdvp/bbc-countryfile-wild-britain-privacy-notice" target="_blank" rel="noopener">Privacy</StyledLink>
      <StyledLink href="https://www.bbc.co.uk/programmes/articles/1RbMtZGJ6SrZJKHDCslSX2S/terms-and-conditions-for-wild-britain" target="_blank" rel="noopener">Terms</StyledLink>
      <StyledLink href="https://bbc.co.uk/countryfile" target="_blank" rel="noopener">Countryfile</StyledLink>
    </div>
    <StyledRouterLink to={'/results'}>Text version</StyledRouterLink>
  </Bar>
);

export default Footer;
